<template>
  <v-list-item
    :href="item.href"
    :rel="item.href ? 'nofollow' : undefined"
    :target="item.href ? '_blank' : undefined"
    :to="item.to"
    :active-class="item.sub ? 'liste-sub-aktif' : 'liste-aktif'"
    link
    class="py-1"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list-item-icon
      v-if="!item.icon"
      class="text-caption text-uppercase justify-center ml-1 my-2 align-self-center"
    >
      {{ title }}
    </v-list-item-icon>

    <v-list-item-avatar v-if="item.avatar">
      <v-img :src="item.avatar" />
    </v-list-item-avatar>

    <v-list-item-icon v-if="item.icon" class="my-2 align-self-center">
      <v-badge
        v-if="item.badge && acikSiparis > 0"
        overlap
        :content="acikSiparis"
        color="error"
      >
        <v-icon v-text="item.icon" />
      </v-badge>
      <v-icon v-else v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { veri, depo } from "./../../db";
export default {
  name: "DefaultListItem",

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    acikSiparis: 0
  }),
  computed: {
    title() {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join("");
    }
  },
  mounted() {
    veri
      .ref("siparisler")
      .orderByChild("durum")
      .equalTo(0)
      .on("value", event => {
        if (event.exists()) {
          Object.size = function(obj) {
            var size = 0;
            var key;
            for (key in obj) {
              if (obj.hasOwnProperty(key)) size++;
            }
            return size;
          };
          this.acikSiparis = Object.size(event.val());
        } else {
          this.acikSiparis = 0;
        }
      });
  }
};
</script>
<style lang="sass">
@import "~vuetify/src/styles/styles.sass"
.liste-sub-aktif
  background-color: rgba( #9c27b0 , 25%)
.liste-aktif
  border-radius: 0 !important
  box-shadow: -5px 0 0 0 #9c27b0
   background-color: rgba( #9c27b0 , 25%)
</style>
